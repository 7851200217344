import { kebabCase } from "lodash"
import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { Row } from "../components/Row"
import { Column } from "../components/Column"
import styled from "styled-components"

import "../styles/global.css"

const ProjectSection = styled.section`
  padding-top: 30px;
  background-color: #fff;
  min-height: 100vh;
`

const ProjectWrapper = styled.div`
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 1rem;

  @media only screen and (min-width: 75em) {
    margin: auto;
    max-width: 85.2rem;
  }

  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 73.2rem;
  }
`

const Projects = ({ data }) => {
  const projects = data.allMdx.edges
  return (
    <Layout>
      <SEO title="Projects" />
      <ProjectSection>
        <ProjectWrapper>
          <h2 className="margin-b-0 slim">MY PROJECTS</h2>
          <Row breakpoints={[530]}>
            {projects.map(project => (
              <Column widths={[6]}>
                <Row breakpoints={[935]}>
                  <Column widths={[6]}>
                    <Link to={project.node.frontmatter.path}>
                      <Img
                        fluid={
                          project.node.frontmatter.featuredImage.childImageSharp
                            .fluid
                        }
                        className="shadow"
                        objectFit="cover"
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: 220,
                        }}
                      />
                    </Link>
                  </Column>
                  <Column widths={[6]}>
                    <h2 className="margin-b-5">
                      {project.node.frontmatter.title}
                    </h2>
                    {project.node.frontmatter.tags ? (
                      <div className="flex-wrapped">
                        {project.node.frontmatter.tags.map(tag => (
                          <p
                            className="margin-t-5 margin-b-5"
                            key={tag + `tag`}
                          >
                            <Link
                              className="p-tag"
                              to={`/tagged/${kebabCase(tag)}/`}
                            >
                              {tag.toUpperCase()}
                            </Link>
                          </p>
                        ))}
                      </div>
                    ) : null}
                    <div>
                      <p className="margin-b-5">
                        {project.node.frontmatter.desc}
                      </p>
                    </div>
                  </Column>
                </Row>
              </Column>
            ))}
          </Row>
        </ProjectWrapper>
      </ProjectSection>
    </Layout>
  )
}

export default Projects

export const projectQuery = graphql`
  query {
    allMdx(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
            desc
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
